import { convertHexToRGBA } from 'util/colors'
import graphql, { getData, getResult, mapErrorsToFormFields } from 'api/graphql'

export const basicFields = `
  id
  gid
  name
  email
  forward_email_address
  from_name
  folders {
    id
    name
    type
  }
  channel_type
  state
  color
  restriction_type
  user_ids
  group_ids
  created_at
  position
  email_integration_error {
    error_type
    error_message
  }
  preferences {
    ai_drafts_mailbox_ids
    ai_drafts_knowledge_base_ids
    ai_quality_rating
  }
`

export const allFields = `
  ${basicFields}
  signature
  use_agent_name
  outgoing_email_server {
    id
    address
    user_name
    port
    authentication
    use_ssl
    verified
    created_at
    updated_at
  }
  incoming_email_server {
    id
    address
    user_name
    port
    use_ssl
    verified
    created_at
    updated_at
  }
  email_templates {
    state
    subject
    body
    purpose
  }
  auto_bcc_address {
    email
  }
  email_integration_error {
    error_type
    error_message
  }
`

export const query = mailboxId => `
  query MailboxQuery {
    mailbox(id: "${mailboxId}") {
      ${allFields}
      provider
    }
  }
`

// include_inaccessible is only honoured if the current user is an owner/admin
// otherwise it just returns mailboxes accessible to the user
export const collectionQuery = () => `
  query MailboxesQuery($include_inaccessible: Boolean) {
    mailboxes(include_inaccessible: $include_inaccessible) {
      ${basicFields}
    }
  }
`

export const basicCreationResponseFields = `
  id
  name
  email
  forward_email_address
  channel_type
`

function generateCreateMutation(responseFields) {
  return `
    mutation CreateMailbox($input: MailboxInput!) {
      createMailbox(input: $input) {
        ${responseFields}
      }
    }
  `
}

export function generateUpdateMutation(responseFields) {
  return `
    mutation UpdateMailbox($id: ID!, $input: MailboxInput!) {
      updateMailbox(id: $id, input: $input) {
        ${responseFields}
      }
    }
  `
}

export const DESTROY_MUTATION = `
  mutation destroyMailbox($id: ID!) {
    destroyMailbox(id: $id) {
      id
    }
  }
`

function generateConvertToDemoMutation(responseFields) {
  return `
    mutation convertToDemoMailbox($id: ID!) {
      convertToDemoMailbox(id: $id) {
        ${responseFields}
      }
    }
  `
}

export const createMailbox = (
  token,
  mailbox,
  responseFields = basicCreationResponseFields
) => {
  const mailboxInput = { ...mailbox }
  if (mailboxInput.color) {
    mailboxInput.color = convertHexToRGBA(mailboxInput.color)
  }
  return graphql(token, generateCreateMutation(responseFields), {
    input: mailboxInput,
  })
    .then(getData)
    .then(getResult('createMailbox'))
    .catch(mapErrorsToFormFields)
}

export const updateMailbox = (
  token,
  id,
  mailbox,
  responseFields = basicFields
) => {
  const mailboxInput = { ...mailbox }
  delete mailboxInput.forward_email_address
  delete mailboxInput.id
  if (mailboxInput.color) {
    mailboxInput.color = convertHexToRGBA(mailboxInput.color)
  }
  return graphql(token, generateUpdateMutation(responseFields), {
    id,
    input: mailboxInput,
  })
    .then(getData)
    .then(getResult('updateMailbox'))
    .catch(mapErrorsToFormFields)
}

export const destroyMailbox = (token, id) => {
  return graphql(token, DESTROY_MUTATION, { id })
    .then(getData)
    .then(getResult('destroyMailbox'))
    .catch(mapErrorsToFormFields)
}

export const convertMailboxToDemo = (
  token,
  id,
  responseFields = basicFields
) => {
  return graphql(token, generateConvertToDemoMutation(responseFields), { id })
    .then(getData)
    .then(getResult('convertToDemoMailbox'))
    .catch(mapErrorsToFormFields)
}

export const fetchMailbox = (token, id) => {
  return graphql(token, query(id))
    .then(getData)
    .then(getResult('mailbox'))
}
